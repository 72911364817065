<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        :title="surveyGet.title"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: true, url: '/blog', text: 'Test' },
          { islink: false, url: '', text: surveyGet.title },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="card mb-4" v-if="isLoad">
              <h2 style="text-align: center; margin: 10px">Yükleniyor..</h2>
            </div>
            <div
              v-if="!isLoad && !resultComputing && resultVal == ''"
              class="card"
            >
              <div class="card-body">
                <div class="item7-card-img">
                  <lazy-image
                    :src="
                      'https://storage.terapivitrini.com/' + surveyGet.image
                    "
                    :alt="surveyGet.title"
                    class="w-100"
                  />
                </div>
                <h1 class="page-title">{{ surveyGet.title }}</h1>
                <div v-html="surveyGet.description"></div>
                <!--SORULAR-->
                <div v-for="(item, i) in surveyGet.questions" :key="i">
                  <span>
                    <b>{{ (i + 1).toString() + "-" + item.question_name }}</b>
                    <!--CEVAPLAR-->
                    <div v-for="(answer, a) in item.answers" :key="a">
                      <div class="form-check">
                        <label class="form-check-label" :rel="i">
                          <input
                            type="radio"
                            @click="answerChange(i, answer)"
                            class="form-check-input"
                            :name="i"
                          />{{ answer.answer_name }}
                        </label>
                      </div>
                    </div>
                  </span>
                </div>
                <!--SONUÇLARI GÖSTER-->
                <button
                  class="btn btn-primary mt-5 w-100 router-link-active"
                  @click="result"
                >
                  Sonuçları Göster
                </button>
              </div>
            </div>
            <div v-if="resultComputing && resultVal == ''">
              <div class="card card-body">
                <h2 style="text-align: center; margin: 10px">
                  Sonuç Hesaplanıyor..
                </h2>
              </div>
            </div>
            <div v-if="resultComputing && resultVal != ''">
              <div class="card card-body mb-4" v-html="resultVal.description">
                
              </div>

              <div class="row">
                <div
                  class="col-lg-3 col-md-12 col-xl-3"
                  v-for="(item, i) in resultVal.users"
                  :key="'user' + i"
                >
                  <div class="card tItem overflow-hidden">
                    <div class="item-card9-imgs">
                      <router-link
                        :to="'/terapist/' + item.shortlink"
                      ></router-link>
                      <img
                        :src="
                          'https://storage.terapivitrini.com/' +
                          item.profile_image_url
                        "
                        alt="img"
                        class="w-100"
                      />
                    </div>

                    <div class="card-body">
                      <div class="item-card2">
                        <div class="item-card2-desc text-center">
                          <div class="item-card2-text">
                            <router-link
                              :to="'/terapist/' + item.shortlink"
                              class="text-dark"
                              ><h2
                                class="font-weight-bold mb-1"
                                style="font-size: 20px"
                              >
                                {{ item.fullname }}
                              </h2></router-link
                            >
                            <StarPos
                              :pos="
                                Math.ceil(item.total_score / item.total_comment)
                              "
                              :id="item.id"
                            />
                          </div>
                          <p class="fs-16">{{ item.expert }}</p>
                          <router-link
                          target="_blank"
                            :to="'/terapist/' + item.shortlink"
                            class="btn btn-white btn-sm"
                            >Profil Detayı</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>
                
              </div>
            </div>
            <router-link
            v-if="resultVal"
                  :to="'/search?q=' + resultVal.word_list[0]"
                  class="btn btn-primary mt-5 w-100"
                  >Daha Fazlası</router-link
                >
          </div>
        </div>
      </div>
    </section>
    <!--/Add listing-->
  </div>
</template>
  <script>
import ProfileItem from "../SearchPages/Components/SlidableProfileItem.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import StarPos from "../../components/StarPos.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
        {
          name: "author",
          content: this.author,
        },
        {
          name: "keywords",
          content: this.keywords,
        },
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.image },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let surveyInfo = {
      shortlink: this.$route.params.shortlink,
    };
    this.$store.dispatch("surveyGet", surveyInfo).then((value) => {
      this.surveyGet = value;
      this.title = value.title + " - Terapi Vitrini";
      this.description =
        value.title +
        " - Zihin ruh beden sağlığınız ve yaşadığınız çeşitli sorunlar için ipuçları ve bilgilere ulaşmak için tıklayın.";

      this.isLoad = false;
      let _this = this;
      $(document).ready(function () {
        _this.image =
          "https://storage.terapivitrini.com/" + _this.blogGet.thumbnail;
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          _this.author,
          _this.keywords,
          _this.image,
          content,
        ]);
      });
    });
  },
  data() {
    return {
      surveyGet: [],
      isLoad: true,
      title: "",
      description: "",
      author: "",
      keywords: "",
      image: "",
      selectedAnswers: [],
      resultComputing: false,
      resultVal: "",
    };
  },
  methods: {
    answerChange(questionLocation, answer) {
      this.selectedAnswers[questionLocation] = answer;
    },
    result() {
      //find size
      var i = 0;
      this.selectedAnswers.forEach((e) => {
        i++;
      });
      //control result
      if (i == this.surveyGet.questions.length) {
        //done
        let surveyControl = {
          shortlink: this.$route.params.shortlink,
          answers: JSON.stringify(this.selectedAnswers),
        };
        this.resultComputing = true;
        this.$store.dispatch("surveyControl", surveyControl).then((value) => {
          this.resultVal = value;
        });
      } else {
        this.$vToastify.warning(
          "Soruların tamamına cevap verilmemiş!",
          "Uyarı!"
        );
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  components: { Breadcrumb, ProfileItem, StarPos },
  mounted() {
    this.backToTop();
  },
};
</script>